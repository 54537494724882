html,
* {
  position: relative;
  box-sizing: border-box;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}
.serif {
  font-family: "Source Serif Pro", serif !important;
}
.heading {
  font-family: "Cinzel", serif !important;
}
.pt-1 {
  padding-top: 1rem;
}
.ph-2 {
  padding-left: 1rem;
  padding-right: 1rem;
}
.justify {
  text-align: justify;
}
.text {
  line-height: 2 !important;
  color: #444;
}
.text-img {
  color: white;
  line-height: 2 !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}
fieldset {
  border-radius: 1rem;
}
